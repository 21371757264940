<template>
    <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h4 class="m-0 text-dark">Make Return Order</h4>
                    </div>
                </div><!-- /.row -->
            </div><!-- /.container-fluid -->
        </div>
        <!-- /.content-header -->
        <!-- Main content -->
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-header">
                                <form>
                                    <div class="row">
                                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12">
                                            <div class="form-group">
                                                <label for="title">Merchant</label>
                                                <v-select name="hub_id"
                                                    v-model="search.merchant_id"
                                                    label="text"
                                                    :reduce="item => item.id"
                                                    :options= merchantList
                                                    :placeholder="$t('globalTrans.select')"
                                                />
                                            </div>
                                        </div>
                                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12" v-if="authUser.role_id <= 2">
                                            <div class="form-group">
                                                <label for="title">{{ $t('globalTrans.hub') }}</label>
                                                <v-select name="hub_id"
                                                    v-model="search.hub_id"
                                                    label="text"
                                                    :reduce="item => item.id"
                                                    :options= hubList
                                                    :placeholder="$t('globalTrans.select')"
                                                />
                                            </div>
                                        </div>
                                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12">
                                            <div class="form-group">
                                                <label for="start_date">Start Date</label>
                                                <input type="date" id="start_date" v-model="search.start_date" class="form-control" />
                                            </div>
                                        </div>
                                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12">
                                            <div class="form-group">
                                                <label for="end_date">End Date</label>
                                                <input type="date" id="end_date" v-model="search.end_date" class="form-control" />
                                            </div>
                                        </div>
                                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12">
                                            <div class="form-group">
                                                <button class="btn btn-info btn-sm" type="submit" @click.prevent="searchData" style="margin-top:28px;"><i class="fa fa-search"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-header" v-if="makeReturBtn">
                                <div class="row">
                                    <!-- <div class="col-9"></div> -->
                                    <div class="col-12">
                                        <div style="display:inline-block">
                                            <form class="form-horizontal form-inline">
                                                <div class="form-group mr-2">
                                                    <label for="rider_id">Rider <span class="text-danger" title="Required">*</span></label>
                                                    <v-select name="rider_id"
                                                        v-model="form.rider_id"
                                                        label="text"
                                                        :reduce="item => item.id"
                                                        :options= riderList
                                                        :placeholder="$t('globalTrans.select')"
                                                    />
                                                </div>
                                                <div class="form-group">
                                                    <label for="status">Status <span class="text-danger" title="Required">*</span></label>
                                                    <select v-model="form.status" class="form-control">
                                                        <option :value="25">Return On way to Merchant</option>
                                                    </select>
                                                </div>
                                            </form>
                                        </div>
                                        <div style="display:inline-block">
                                            <button class="btn btn-danger float-right btn-sm ml-2" @click="makeReturn"> Make Return</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body">
                                <b-overlay :show="loader">
                                    <div class="table-responsive">
                                        <table id="exportPdfTable" class="table table-bordered table-striped table-sm">
                                            <thead>
                                                <tr>
                                                    <th>Order ID</th>
                                                    <th>Merchant</th>
                                                    <th>Order Date</th>
                                                    <th>Customer</th>
                                                    <th>Price (BDT)</th>
                                                    <th>Collected (BDT)</th>
                                                    <th>Service Charge</th>
                                                    <th>Return Charge</th>
                                                    <th>Area Charge</th>
                                                    <th>Weight Charge</th>
                                                    <th>COD</th>
                                                    <th>Amount</th>
                                                    <th>Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(order, index) in orders" :key="index">
                                                    <td>
                                                        <label>
                                                            <input v-model="form.order_ids" @click="select(order.id)" :value="order.id" type="checkbox" class="order-checkbox" />
                                                            <p class="d-inline">{{ order.tracking_id }}</p>
                                                        </label>
                                                    </td>
                                                    <td @click="orderLog(order.logs)">{{ order.merchant ? order.merchant.business : '' }}</td>
                                                    <td>{{ order.date | dateformat }}</td>
                                                    <td>{{ order.name }}<br/>{{ order.mobile }}</td>
                                                    <td>{{ order.price }}</td>
                                                    <td>{{ order.collected }}</td>
                                                    <td>{{ order.service_charge }}</td>
                                                    <td>{{ order.return_charge }}</td>
                                                    <td>{{ order.area_charge }}</td>
                                                    <td>{{ order.weight_charge }}</td>
                                                    <td>{{ order.cod }}</td>
                                                    <td>{{ order.amount }}</td>
                                                    <td><span :class="'status-'+order.status">{{ getStatus(order.status) }}</span></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </b-overlay>
                            </div>
                        </div>
                    </div>
                </div>
            </div><!-- /.container-fluid -->
        </section>
        <!-- /.content -->
        <div v-if="orderLogModal" class="modal fade show" style="display:block" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header bg-info text-white">
                        <h5 class="modal-title text-center w-100">Order Log</h5>
                        <button type="button" class="close" aria-label="Close" @click="cancelModal">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <table class="table table-bordered table-sm">
                            <tr>
                                <th>Action By</th>
                                <th>Action</th>
                                <th>Date & Time</th>
                            </tr>
                            <tr v-for="(log, index) in order_logs" :key="index">
                                <td>{{ log.operation_by }}</td>
                                <td>{{ log.operation }}</td>
                                <td>{{ log.date | dateformat }} at {{ log.date | timeformat }}</td>
                            </tr>
                        </table>                              
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-sm btn-danger" @click="cancelModal">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import config from '@/config'
    import moment from 'moment'
    import 'jspdf-autotable'
    export default {
        name:'ReturnForm',
        data () {
            return {   
                orderLogModal: false,
                loader: false,             
                makeReturBtn: false,             
                orders:[],
                search: {
                    merchant_id: '',
                    hub_id: '',
                    start_date: moment().subtract(30,'d').format('YYYY-MM-DD'),
                    end_date: moment().format('YYYY-MM-DD')
                },
                form: {
                    rider_id: 'Select',
                    status: 25,
                    order_ids: []
                },
                params: {
                    merchant_id : this.$route.query.merchant_id,
                    start_date : this.$route.query.start_date,
                    end_date : this.$route.query.end_date
                }
            }
        },
        created(){
            this.getOrders();
        },
        computed : {
            merchantList () {
                return this.$store.state.commonObj.merchantList
            },
            hubList () {
                return this.$store.state.commonObj.hubList
            },
            authUser () {
                return this.$store.state.authUser
            },
            riderList () {
                if (this.authUser.role_id == 7) {
                    return this.$store.state.commonObj.riderList.filter(el => el.hub_id === this.authUser.hub_id)
                }
                
                return this.$store.state.commonObj.riderList
            }
        },
        methods:{
            select(orderId) {
                const tmpOrder = this.form.order_ids.indexOf(orderId)
                if (tmpOrder > -1) {
                    this.form.order_ids.splice(tmpOrder, 1)                   
                } else {
                    this.form.order_ids.push(orderId)
                }      
            },
            searchData () {
                this.getOrders()
            },
            async getOrders(){     
                this.loader = true  
                const params = this.authUser.role_id == 7 ? Object.assign({}, this.search, { hub_id: this.authUser.hub_id }) : this.search
                const response = await config.getData('/hub/return/returnable', params)
                this.loader = false
                if (response.success){
                    this.orders = response.data
                    this.makeReturBtn = (this.search.merchant_id != 0 && this.orders.length > 0) ? true : false
                } else {
                    this.orders = []
                    this.makeReturBtn = false
                } 
            },
            async makeReturn () {
                this.loader = true
                const formData = Object.assign(this.form, { merchant_id: this.search.merchant_id, hub_id: this.authUser.role_id == 7 ? this.authUser.hub_id : this.search.hub_id })
                const isHubExist = this.checkValidation(formData)
                if (isHubExist) {
                    const response = await config.postData('/hub/return/store', formData)
                    this.loader = false
                    if (response.success) {
                        this.$toast.success({
                            title: 'Success',
                            message: 'Retrurn maked successfully',
                            color: '#218838'
                        }) 
                        this.$router.push('/hub-return-list')
                    } else {
                        this.$toast.error({
                            title: 'Error',
                            message: 'Sorry, something went wrong',
                            color: '#dc3545'
                        }) 
                    }
                }
            },    
            getStatus (status) {
                const tmpStatus = this.$store.state.statusList.find(tmp => tmp.id == status)
                return tmpStatus.text
            },
            orderLog (logs) {
                this.order_logs = logs 
                this.orderLogModal = true
            },
            cancelModal () {
                this.orderLogModal = false
            },
            checkValidation (data) {
                if (data.order_ids.length == 0) {
                    this.$toast.error({
                        title: 'Error',
                        message: 'Please select order',
                        color: '#dc3545'
                    }) 
                    return false
                }
                if (data.hub_id == 0) {
                    this.$toast.error({
                        title: 'Error',
                        message: 'Please select hub',
                        color: '#dc3545'
                    }) 
                    return false
                }
                this.loader = false
                return true
            }
        }
    }
</script>

<style scoped>
    .d-inline {
        display: inline-block;
        padding-left: 3px;
    }
</style>